body {
  margin: 0;
  font-family: 'Spoqa Han Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='search' i]::-webkit-search-cancel-button {
  display: none;
}

/* hide scrollbar  */
*:not(.scrollBox) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*:not(.scrollBox)::-webkit-scrollbar {
  display: none;
}
